import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { Grid } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import accountPrograms from "../../../../api/accountPrograms";
import { AccountProgram } from "../../../../model/AccountProgram";
import { Process } from "../../../../model/Process";
import { ProcessCategory } from "../../../../model/ProcessCategory";
import { AccountPageContext } from "./AccountProgramPage";
import BuilderPanel from "./builder-components/BuilderPanel";
import ProcessCard from "./builder-components/ProcessCard";
import ServicesPanel from "./builder-components/ServicesPanel";

type ProgramEditorCardProps = {
  card: Process;
  category: ProcessCategory;
  currentPanel: string;
};

const AccountProgramEditor = () => {
  const {
    accountTypeProcesses,
    account,
    categories,
    organization,
    programs,
    dispatch,
  } = useContext(AccountPageContext);
  const [listProcesses, setListProcesses] = useState<Process[]>([]);
  const [builderProcesses, setBuilderProcesses] = useState<Process[]>([]);
  const [active, setActive] = useState<ProgramEditorCardProps>();

  // Maps for finding objects efficiently
  const processMap = useMemo(() => {
    const processMap = new Map<string, Process>();
    accountTypeProcesses.forEach((process) =>
      processMap.set(process.meta ?? process.id, process),
    );
    return processMap;
  }, [accountTypeProcesses]);

  const programMap = useMemo(() => new Map<string, AccountProgram>(), []);

  const categoryMap = useMemo(() => {
    const categoryMap = new Map<string, ProcessCategory>();
    const programCategory = categories?.find((c) => c.label == "Program");

    categories
      ?.filter((category) => {
        if (!programCategory) return true;
        let parentId = category.parent_id;
        while (parentId != undefined) {
          if (parentId == programCategory.id) return true;
          parentId = categories.find((c) => c.id == category.parent_id)
            ?.parent_id;
        }
        return false;
      })
      .forEach((category) =>
        categoryMap.set(category.id, {
          ...category,
          parent_id:
            category.parent_id == programCategory?.id
              ? undefined
              : category.parent_id,
        }),
      );
    categoryMap.set(programCategory ? programCategory.id : "", {
      id: programCategory ? programCategory.id : "",
      label: "Other",
      org_id: organization.id,
    });
    return categoryMap;
  }, [categories, organization.id]);

  useEffect(() => {
    if (programs) {
      programs.forEach((program) => {
        if (!programMap.get(program.process_meta)) {
          programMap.set(program.process_meta, program);
        }
      });
    }
  }, [programMap, programs]);

  useEffect(() => {
    // sorts the processes into the list or builder depending on whether or not an existing program
    // exists for a given process

    const newBuilderProcesses: Process[] = [];
    const newListProcesses: Process[] = [];

    programs.forEach((program) => {
      const process = processMap.get(program.process_meta);
      if (process) newBuilderProcesses.push(process);
    });

    accountTypeProcesses.forEach((process) => {
      if (!programMap.get(process.meta!)) {
        newListProcesses.push(process);
      }
    });

    setBuilderProcesses(newBuilderProcesses);
    setListProcesses(newListProcesses);
  }, [accountTypeProcesses, processMap, programMap, programs]);

  const handleDragStart = (event: DragStartEvent) => {
    const [process, category, currentPanel] = [
      event.active.data.current?.card,
      event.active.data.current?.category,
      event.active.data.current?.currentPanel,
    ];

    setActive({
      card: process,
      category: category,
      currentPanel: currentPanel,
    });
  };

  const updateProgramOrder = (newPrograms: AccountProgram[]) => {
    for (let i = 0; i < newPrograms.length; i++) {
      let previousId: string | undefined;
      if (i > 0) {
        previousId = newPrograms[i - 1].id;
      }

      const program = newPrograms[i];

      // if the new previousId is different than the old one, upadte the program in the db
      if (previousId !== program.previous_id) {
        program.previous_id = previousId;

        accountPrograms.update(program).then((newProgram) => {
          programMap.set(newProgram.process_meta, newProgram);
        });
      }
    }

    dispatch({ programs: newPrograms });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const [card, fromPanel] = [
      event.active.data.current?.card,
      event.active.data.current?.currentPanel,
    ];

    // check to see if the card was dropped over one of the panels
    // if not, it was dropped on a card and the panel should be gotten from that

    const newListProcesses = listProcesses.slice();
    const newBuilderProcesses = builderProcesses.slice();

    let toPanel = event.over?.id;
    if (toPanel !== "services" && toPanel !== "builder") {
      toPanel = event.over?.data.current?.currentPanel;
    }

    if (fromPanel !== toPanel) {
      // removes the card from the old panel
      if (fromPanel === "services") {
        const index = newListProcesses.indexOf(card);
        if (index !== -1) newListProcesses.splice(index, 1);
      } else {
        const index = newBuilderProcesses.indexOf(card);
        const program = programMap.get(card.meta);
        if (index !== -1) newBuilderProcesses.splice(index, 1);

        // if a program existed for a given process, delete it and remove from map
        if (program) {
          programMap.delete(card.meta);
          accountPrograms.remove(program.id).then(() => {
            updateProgramOrder(Array.from(programMap.values()));
          });
        }
      }

      // adds the card to the new panel
      if (toPanel === "services") {
        newListProcesses.push(card);
      } else {
        newBuilderProcesses.push(card);

        // stores the request in the map so duplicate programs can not be created
        if (!programMap.get(card.meta)) {
          const request = {
            account_id: account?.id,
            process_meta: card.meta,
            previous_id:
              programs.length > 0
                ? programs[programs.length - 1].id
                : undefined,
          } as AccountProgram;

          programMap.set(card.meta, request);

          // updates the map with the new program when it is successfully created
          accountPrograms.create(request).then((program) => {
            programMap.set(card.meta, program);
            // programs updated manually since the order is unchanged
            dispatch({ programs: [...programs, program] });
          });
        }
      }

      setListProcesses(newListProcesses);
      setBuilderProcesses(newBuilderProcesses);
    } else {
      // if the panels are the same and it is the builder panel,
      // sort the array
      if (toPanel === "builder") {
        const fromIndex = event.active.data.current?.sortable.index;
        const toIndex = event.over?.data.current?.sortable.index;

        const sortedArray = arrayMove(newBuilderProcesses, fromIndex, toIndex);
        const newPrograms: AccountProgram[] = [];
        for (let i = 0; i < sortedArray.length; i++) {
          const program = programMap.get(sortedArray[i].meta!);
          if (program) {
            newPrograms.push(program);
          }
        }

        updateProgramOrder(newPrograms);
        setBuilderProcesses(sortedArray);
      }
    }
  };

  const toggleVisibility = (process: Process, visible: boolean) => {
    const program = programMap.get(process.meta ?? process.id);
    if (program) {
      program.visible = visible;
      accountPrograms.update(program);
    }
  };

  return (
    <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Grid
          item
          xs={6}
          sx={{
            height: "100%",
          }}
        >
          <ServicesPanel cards={listProcesses} categories={categoryMap} />
        </Grid>
        <Grid item xs={6} sx={{ height: "100%" }}>
          <SortableContext
            items={builderProcesses.map((process) => process.id)}
          >
            <BuilderPanel
              cards={builderProcesses}
              programs={programMap}
              categories={categoryMap}
              toggleVisibility={toggleVisibility}
            />
          </SortableContext>
        </Grid>
      </Grid>
      <DragOverlay>{active && <ProcessCard {...active} overlay />}</DragOverlay>
    </DndContext>
  );
};

export default AccountProgramEditor;
