import { useCallback } from "react";
import { AccountAPI } from "../../../api";
import { useAlert } from "../../../lib/alert";
import { Account, AccountDto } from "../../../model/Account";
import { FieldValue } from "../../../model/FieldDefinition";

type UpdatedFieldDef = {
  fieldDefId: string;
  elementId: string;
  value: FieldValue | undefined;
};

type useUpdatedAccountFieldsProps = {
  accountDto: AccountDto;
  updatedFieldDefs: UpdatedFieldDef[];
  setUpdatedFieldDefs: (updatedFieldDefs: UpdatedFieldDef[]) => void;
  onClose?: () => void;
  onUpdate?: (account: Account) => void;
};

export const useUpdatedAccountFields = ({
  accountDto,
  updatedFieldDefs,
  setUpdatedFieldDefs,
  ...props
}: useUpdatedAccountFieldsProps) => {
  const { handleRejectionWithError } = useAlert();

  const updateAccountFieldValues = useCallback(async (): Promise<void> => {
    // Prepare updated fields object
    const updatedFieldObject = accountDto.fields ?? {};
    updatedFieldDefs.forEach((updatedFieldDef) => {
      // Ensure field definition exists
      if (!updatedFieldObject[updatedFieldDef.fieldDefId]) {
        updatedFieldObject[updatedFieldDef.fieldDefId] = {
          updated_at: Date.now(),
          value: {},
        };
      } else {
        updatedFieldObject[updatedFieldDef.fieldDefId].updated_at = Date.now();
      }

      // Update or remove values
      if (updatedFieldDef.value !== undefined) {
        updatedFieldObject[updatedFieldDef.fieldDefId].value[
          updatedFieldDef.elementId
        ] = updatedFieldDef.value;
      } else {
        delete updatedFieldObject[updatedFieldDef.fieldDefId].value[
          updatedFieldDef.elementId
        ];
      }
    });

    await AccountAPI.update({
      id: accountDto.id,
      org_id: accountDto.org_id,
      account_type_id: accountDto.account_type_id,
      account_type: accountDto.account_type,
      fields:
        Object.keys(updatedFieldObject).length != 0
          ? updatedFieldObject
          : undefined,
    }).then((account) => {
      setUpdatedFieldDefs([]);
      props.onClose?.();
      props.onUpdate?.(account);
    }, handleRejectionWithError("Failed to update account"));
  }, [
    props,
    accountDto,
    updatedFieldDefs,
    setUpdatedFieldDefs,
    handleRejectionWithError,
  ]);

  return { updateAccountFieldValues };
};

export default useUpdatedAccountFields;
