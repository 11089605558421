import { CheckCircleOutline, HourglassBottom } from "@mui/icons-material";
import { Chip, SxProps, Tooltip, Typography } from "@mui/material";
import { ReactElement, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { ProcessExecution } from "../../../model/Process";
import messages from "../messages";

/**
 * Type defined to show a certain status in the chip
 * Includes a tooltip ReactElement to display custom information from the process passed in
 */
type ProcessExecutionStatusType = {
  icon: JSX.Element;
  color:
    | "default"
    | "success"
    | "warning"
    | "error"
    | "primary"
    | "secondary"
    | "info";
  bgcolor: string;
  title: string;
  tooltip: ReactElement;
};

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    complete: true;
  }
}

const ProcessExecutionStatusChip = ({
  process,
  onClick,
  sx,
}: {
  process: ProcessExecution;
  onClick?: () => void;
  sx?: SxProps;
}) => {
  const intl = useIntl();
  const [status] = useState(process.state);

  const executionMsg = intl.formatMessage(messages.execution.props);
  const inProgressExecutionsMsg = intl.formatMessage(
    messages.inProgressExecutions.props,
  );
  const completedExecutionsMsg = intl.formatMessage(
    messages.completedExecutions.props,
  );

  /**
   * Gets the current process execution status info based on the status
   * Uses the ProcessExecutionStatusType to define the various types that can be placed into the chip
   * Current possible values are "IN_PROGRESS" and "COMPLETED"
   */
  const currentProcessExecutionStatus = useMemo(() => {
    const availableProcessExecutionStatusTypes: {
      [key: string]: ProcessExecutionStatusType;
    } = {
      IN_PROGRESS: {
        icon: <HourglassBottom />,
        bgcolor: "default",
        color: "default",
        title: inProgressExecutionsMsg,
        tooltip: (
          <>
            <Typography>This {executionMsg} is still in-progress!</Typography>
          </>
        ),
      },
      COMPLETED: {
        icon: <CheckCircleOutline sx={{ color: "#ffffff" }} />,
        bgcolor: "complete",
        color: "success",
        title: completedExecutionsMsg,
        tooltip: (
          <>
            <Typography>This {executionMsg} has been completed!</Typography>
          </>
        ),
      },
    };

    if (!status) return undefined;

    return availableProcessExecutionStatusTypes[status];
  }, [completedExecutionsMsg, executionMsg, inProgressExecutionsMsg, status]);

  return (
    <>
      {/* Defines a tooltip to place around the label depending on the state of the process
        TODO: Include tracking information and custom tooltips to do so
    */}
      {currentProcessExecutionStatus && (
        <Tooltip title={currentProcessExecutionStatus.tooltip}>
          <Chip
            icon={currentProcessExecutionStatus.icon}
            component="a"
            onClick={onClick}
            label={currentProcessExecutionStatus.title}
            color={currentProcessExecutionStatus.color}
            sx={{
              bgcolor: (theme) => {
                if (currentProcessExecutionStatus.bgcolor === "default") return;
                if (currentProcessExecutionStatus.bgcolor === "complete")
                  return theme.palette.progress.complete;
              },
              paddingLeft: "4px",
              ...sx,
            }}
          />
        </Tooltip>
      )}
    </>
  );
};
export default ProcessExecutionStatusChip;
