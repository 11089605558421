import { AddCircle } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import useCreateAndNavigateToProcess from "../hooks/useCreateAndNavigateToProcess";
import messages from "../messages";
import CreateProcessDialog from "./CreateProcessDialog";

export type ProcessListHeaderProps = {
  entityTypeId?: string;
};

const ProcessListActionBar = () => {
  const intl = useIntl();
  const process = intl.formatMessage(messages.process.props);
  const [processCreateDialogOpen, setProcessCreateDialogOpen] = useState(false);
  const createAndNavigateToProcess = useCreateAndNavigateToProcess();
  return (
    <>
      <Box display="flex" gap="24px">
        <Button
          data-cy="create-process-btn"
          variant="contained"
          onClick={() => setProcessCreateDialogOpen(true)}
        >
          <AddCircle sx={{ mr: "0.25em" }} />
          {`Create ${process}`}
        </Button>
      </Box>
      <CreateProcessDialog
        open={processCreateDialogOpen}
        showSubjectField={false}
        handleConfirm={createAndNavigateToProcess}
        handleCancel={function (): void {
          setProcessCreateDialogOpen(false);
        }}
      />
    </>
  );
};
export default ProcessListActionBar;
