import { Container } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router";
import Organizations from "../../api/organizations";
import {
  PageContainer,
  PageContent,
  PageHeader,
} from "../../components/layout/page";
import PATHS from "../../components/navigation/_paths";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";
import { useAlert } from "../../lib/alert";
import { Organization } from "../../model";
import NotFound from "../app/NotFound";
import UpdateOrganization from "./components/UpdateOrganization";

const UpdateOrganizationPage: FC<unknown> = () => {
  const { success } = useAlert();
  const navigate = useNavigate();
  const { organization, setOrganization, refreshOrganizations } =
    useGlobalOrganizationContext();

  if (organization) {
    return (
      <PageContainer variant="full">
        <PageHeader title="Manage Organization" />
        <PageContent>
          <Container maxWidth="md" sx={{ p: 2 }}>
            <UpdateOrganization
              organization={organization}
              onSubmit={async (organization: Organization) => {
                const updatedOrganization =
                  await Organizations.update(organization);
                refreshOrganizations();
                setOrganization(updatedOrganization);
                success("Successfully updated organization");
              }}
              onOrganizationDeleted={() => {
                refreshOrganizations().then(() => {
                  navigate(PATHS.USER_MANAGE_ORGANIZATIONS.link);
                });
              }}
              billingEmailDisabled={true}
            />
          </Container>
        </PageContent>
      </PageContainer>
    );
  }
  return <NotFound />;
};

export default UpdateOrganizationPage;
