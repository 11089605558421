import { ProcessCategory } from "./ProcessCategory";

export type OrganizationTheme = {
  shortLogoUrl?: string;
  longLogoUrl?: string;
  faviconUrl?: string;
  palette?: unknown;
  typography?: unknown;
};

export type PublicOrganizationPortalConfig =
  | {
      group: "category";
      categories: ProcessCategory[];
    }
  | {
      group: "accountType" | "none";
    };

export type OrganizationConfig = {
  portal?: PublicOrganizationPortalConfig;
  features?: Record<string, boolean>;
};

export type OrganizationWellKnownProcesses = {
  email?: string;
  invitation?: string;
};

export default class Organization {
  public id = "";
  public owner_id = "";
  public name = "";
  public key = "";
  public description: string | undefined = "";
  public theme?: OrganizationTheme;
  public config?: OrganizationConfig;
  public well_known_processes?: OrganizationWellKnownProcesses;
  public email?: string;
  public features?: string[];
  public billing_email?: string;
}
