import {
  Checkbox,
  DialogContentText,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { AccountTypeAPI } from "../../../../api";
import Dialog from "../../../../components/Dialog";
import { Account } from "../../../../model";
import { AccountDto } from "../../../../model/Account";
import { FieldValue } from "../../../../model/FieldDefinition";
import {
  FormElementType,
  FormExecutionType,
  SelectionOption,
} from "../../../task";
import useUpdatedAccountFields from "../../hooks/useUpdatedAccountFields";
import AccountFieldsAccordion from "../AccountFieldsAccordion";

type EditAccountDialogProps = {
  open: boolean;
  accountDto: AccountDto;
  onClose?: () => void;
  onUpdate?: (account: Account) => void;
};
const EditAccountDialog: FC<EditAccountDialogProps> = ({
  accountDto,
  ...props
}) => {
  const [name, setName] = useState(accountDto?.account_name ?? "");
  const [emailAddress, setEmailAddress] = useState<string | undefined>(
    accountDto.primary_email,
  );
  const [isTest, setIsTest] = useState<boolean>(accountDto?.is_test ?? false);
  const [errorText, setErrorText] = useState<string | undefined>(undefined);
  const [updatedFieldDefs, setUpdatedFieldDefs] = useState<
    Array<{
      fieldDefId: string;
      elementId: string;
      value: FieldValue | undefined;
    }>
  >([]);
  const [fieldDefinitionInfo, setFieldDefinitionInfo] = useState<
    Array<{
      id: string;
      label: string;
      elements: Array<{
        id: string;
        type?: FormElementType;
        valueLabel?: string;
        execution?: FormExecutionType;
      }>;
      accordionOpen: boolean;
    }>
  >([]);
  const { updateAccountFieldValues } = useUpdatedAccountFields({
    accountDto,
    updatedFieldDefs,
    setUpdatedFieldDefs,
    onClose: props.onClose,
    onUpdate: props.onUpdate,
  });
  const title = accountDto.account_name
    ? `Update ${accountDto.account_name}`
    : "Update Account";

  const getFieldDefValueLabel = (
    elementType: FormElementType | undefined,
    elementValue: FieldValue | undefined,
  ) => {
    switch (elementType) {
      case FormElementType.Selection:
      case FormElementType.Radio:
        return (elementValue as SelectionOption)?.name;
      case FormElementType.Autocomplete:
      case FormElementType.Text:
        return elementValue as string;
      case FormElementType.Checkbox:
        return (elementValue as boolean) ? "True" : "False";
      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (accountDto.primary_email) {
      setEmailAddress(
        (emailAddress) => emailAddress ?? accountDto?.primary_email,
      );
    }
    if (accountDto.account_name) {
      setName((name) => name ?? accountDto?.account_name);
    }
    if (accountDto.account_type_id) {
      AccountTypeAPI.getFieldDefinitions(accountDto.account_type_id).then(
        ({ selected, available }) => {
          setFieldDefinitionInfo(
            selected.map((fieldDefId) => {
              const fieldDef = available[fieldDefId];

              const accountFieldDefValue = accountDto.fields
                ? accountDto.fields[fieldDefId]
                  ? accountDto.fields[fieldDefId].value
                  : undefined
                : undefined;

              return {
                id: fieldDef.id,
                label: fieldDef.label,
                elements: fieldDef.data.elements.map((element) => {
                  const elementValue = accountFieldDefValue
                    ? accountFieldDefValue[element.id]
                    : undefined;

                  return {
                    id: element.id,
                    type: element.type,
                    valueLabel: getFieldDefValueLabel(
                      element.type,
                      elementValue,
                    ),
                    execution: {
                      ...element,
                      value: elementValue,
                    } as FormExecutionType,
                  };
                }),
                accordionOpen: false,
              };
            }),
          );
        },
      );
    }
  }, [accountDto]);

  return (
    <Dialog
      open={props.open}
      title={title}
      confirmText={"Update"}
      cancelText={"Cancel"}
      handleConfirm={async (): Promise<void> => {
        if (!name) {
          const errorText = "Account name must be specified";
          setErrorText(errorText);
          return Promise.reject(errorText);
        }
        await updateAccountFieldValues();
      }}
      handleCancel={function (): void {
        props.onClose && props.onClose();
      }}
    >
      <DialogContentText>
        Change the name for the account and the email of the primary contact:
        {errorText && (
          <Typography variant="body1" sx={{ color: "red" }}>
            {errorText}
          </Typography>
        )}
      </DialogContentText>
      <TextField
        autoFocus
        margin="dense"
        id="name"
        label="Account Name"
        type="text"
        fullWidth
        variant="standard"
        value={name}
        onChange={(e) => setName(e.target.value as string)}
      />
      <TextField
        autoFocus
        margin="dense"
        id="email"
        label="Email Address"
        type="text"
        fullWidth
        variant="standard"
        value={emailAddress ?? ""}
        onChange={(e) => setEmailAddress(e.target.value as string)}
      />
      <FormControlLabel
        control={
          <Checkbox checked={isTest} onChange={() => setIsTest(!isTest)} />
        }
        label="Mark as test account"
      />
      {fieldDefinitionInfo.length != 0 && (
        <DialogContentText sx={{ mt: 2, mb: 1 }}>
          {accountDto.account_name ?? "Account"} Fields:
        </DialogContentText>
      )}
      <AccountFieldsAccordion accountDto={accountDto} />
    </Dialog>
  );
};

export default EditAccountDialog;
